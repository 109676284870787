import React, {useState} from 'react';
import logo from "../static/main_logo.svg";
const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu = () => {

        setIsOpen(!isOpen);
    };
    return (
        <div>
            <div className={"menu-helper"} style={isOpen ? {backgroundColor: "black"} : {}}></div>
            <div className={"menu"} >
                <img className={"logo"} src={logo} alt={"logo"}/>
                <nav className={"desktop"}>
                    <ul>
                        <li>
                            <a href="#proj">Проекты</a>
                        </li>
                        <li>
                            <a href="#elit">E-литература</a>
                        </li>
                        <li>
                            <a href="#pub">Публикации</a>
                        </li>
                        <li>
                            <a href="#tgproj">Телеграм: проекты</a>
                        </li>
                        <li>
                            <a href="#dev">Разработка</a>
                        </li>
                        <li>
                            <a href="#about">О себе</a>
                        </li>
                        <li>
                            <a href="#cont">Контакты</a>
                        </li>
                    </ul>
                </nav>
                <nav className={"mobile"}>
                    <div className={isOpen ? "burger-icon diam" : "burger-icon"} onClick={toggleMenu}>
                        <span ></span>
                        <span></span>
                        <span></span>
                    </div>
                    {isOpen && (
                        <div style={{backgroundColor: "black"}}>
                    <ul>
                        <li>
                            <a onClick={toggleMenu} href="#proj">Проекты</a>
                        </li>
                        <li>
                            <a onClick={toggleMenu} href="#elit">E-литература</a>
                        </li>
                        <li>
                            <a onClick={toggleMenu} href="#pub">Публикации</a>
                        </li>
                        <li>
                            <a onClick={toggleMenu} href="#tgproj">Телеграм: проекты</a>
                        </li>
                        <li>
                            <a onClick={toggleMenu} href="#dev">Разработка</a>
                        </li>
                        <li>
                            <a onClick={toggleMenu} href="#about">О себе</a>
                        </li>
                        <li>
                            <a onClick={toggleMenu} href="#cont">Контакты</a>
                        </li>
                    </ul>
                        </div>
                            )}
                </nav>
            </div>
        </div>
    )
}
export default Menu