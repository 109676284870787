import backgroundMob from "./ns_mob.gif"
import backgroundDesc from "./ns_desc.gif";
import React, {useEffect, useState} from 'react';
import "./Ns.css"
import {isMobileDeviceV2} from "../../utils/StyleUtils";
import iIcon from "../static/i.svg"
import soundWaves from "./waves.mp3"
import useSound from "use-sound";

export const getLanguage = () => {
    const langLocal = navigator.language || navigator.userLanguage
    switch (langLocal) {
        case 'en-En':
            return 'en'
        case 'ru-Ru':
            return 'ru'
        default:
            return 'en'
    }
};
const Ns = () =>{
    const [play] = useSound(soundWaves, { loop: true });
    useEffect(() =>{
        play()
    }, [play])

    const [isMob, setIsMob] = useState(isMobileDeviceV2())
    const [lang, setLang] = useState(getLanguage())
    const [showI, setShowI] = useState(false)

    useEffect(() => {
        const handleResize = () => {
            setIsMob(isMobileDeviceV2());
        };

        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleChangeLanguage = () => {
        setLang(lang === 'ru' ? 'en' : 'ru')
    }

    const handleShowI = () => {
        setShowI(!showI)
    }
    const Rus = () => {
        return (
            <div className={isMob ? "grid-container" :"grid-container"}>
            <div className={isMob ? "grid-item" : "grid-item"}>
                <p>есть что-то кроме ностальгии</p>
                <p>игра воображения —</p>
                <p>попытка быть сопричастным,</p>
                <p>просматривая</p>
            </div>
                <div className={isMob ? "grid-item" : "grid-item"}>
                    <p>в той стране где Хади Такташ разрослась,</p>
                    <p>переливаясь за границы</p>
                    <div style={{paddingBottom: '0.2rem'}}></div>
                    <p>дышать невозможно —</p>
                    <p>но мурашки приятного</p>
                    <div style={{paddingBottom:  '0.1rem'}}></div>
                    <p>октябрь 2023</p>
                </div>
            </div>
        )
    }
    const Eng = () => {
        return (
            <div className={isMob ? "grid-container" :"grid-container"}>
                <div className={isMob ? "grid-item" : "grid-item"}>
                    <p>is there anything other than nostalgia</p>
                    <p>game of fantasy —</p>
                    <p>attempt of being involved,</p>
                    <p>reviewing</p>
                </div>
                <div className={isMob ? "grid-item" : "grid-item"}>
                    <p>in the land where Hadi Taktash grew big,</p>
                    <p>overflowing the edges</p>
                    <div style={{paddingBottom: '0.2rem'}}></div>
                    <p>breathing is unfeasible —</p>
                    <p>but cold shivers of delightful</p>
                    <div style={{paddingBottom: '0.1rem'}}></div>
                    <p>october 2023</p>
                </div>
            </div>
        )
    }


    return (
        <div className={"ns"}>
            {showI && <div className={"info"}>ali aliev x katya mezentseva</div> }
            <div className={"i-icon"} onClick={handleShowI}><img src={iIcon} alt={""}/></div>
            <div onClick={handleChangeLanguage} className={"button"}><p>{lang === 'ru' ? 'ru' : 'eng'}</p></div>
            {
                lang === 'ru' ? <Rus/> : <Eng/>
            }
            <div style={{minWidth: isMob ? 300 : 700}} className="svg-container">
                <img src={isMob ? backgroundMob : backgroundDesc} alt="Description" />
            </div>
        </div>
    );
}

export default Ns