// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.social{
    div {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 2rem;
    }
    img {
        width: 40px;
        height: 40px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/_inner/social/Social.css"],"names":[],"mappings":"AAAA;IACI;QACI,oBAAoB;QACpB,8BAA8B;QAC9B,mBAAmB;QACnB,mBAAmB;IACvB;IACA;QACI,WAAW;QACX,YAAY;IAChB;AACJ","sourcesContent":[".social{\n    div {\n        display: inline-flex;\n        justify-content: space-between;\n        align-items: center;\n        padding-right: 2rem;\n    }\n    img {\n        width: 40px;\n        height: 40px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
