// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
    padding: 0;
    margin: 0;
}

.center-custom{
    display: flex;
    align-items: center;
    justify-content: center;
}
/*mobile*/
@media (max-width: 400px){
    .homepage {
        padding: 0.5rem 1.5rem 0.25rem 1.5rem ;
    }
    .desktop {
        display: none;
    }
    .mobile {
        display: block;
        /*background-color: black;*/
        margin: 0;
        width: 50rem;
        left: 0;
        position: fixed;
        padding-left: 1.5rem;
    }
    li {
        display: block;
        margin-right: 3rem;
    }
}

/*desktop*/
@media (min-width: 400px){
    .homepage {
        padding: 2rem 6rem 1rem 6rem ;

    }
    .desktop {
        display: block;
    }
    .mobile {
        display: none;
    }
    li {
        display: inline-block;
        margin-right: 3rem;
    }
}

iframe
{
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;AACA,SAAS;AACT;IACI;QACI,sCAAsC;IAC1C;IACA;QACI,aAAa;IACjB;IACA;QACI,cAAc;QACd,2BAA2B;QAC3B,SAAS;QACT,YAAY;QACZ,OAAO;QACP,eAAe;QACf,oBAAoB;IACxB;IACA;QACI,cAAc;QACd,kBAAkB;IACtB;AACJ;;AAEA,UAAU;AACV;IACI;QACI,6BAA6B;;IAEjC;IACA;QACI,cAAc;IAClB;IACA;QACI,aAAa;IACjB;IACA;QACI,qBAAqB;QACrB,kBAAkB;IACtB;AACJ;;AAEA;;IAEI,aAAa;AACjB","sourcesContent":["body{\n    padding: 0;\n    margin: 0;\n}\n\n.center-custom{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n/*mobile*/\n@media (max-width: 400px){\n    .homepage {\n        padding: 0.5rem 1.5rem 0.25rem 1.5rem ;\n    }\n    .desktop {\n        display: none;\n    }\n    .mobile {\n        display: block;\n        /*background-color: black;*/\n        margin: 0;\n        width: 50rem;\n        left: 0;\n        position: fixed;\n        padding-left: 1.5rem;\n    }\n    li {\n        display: block;\n        margin-right: 3rem;\n    }\n}\n\n/*desktop*/\n@media (min-width: 400px){\n    .homepage {\n        padding: 2rem 6rem 1rem 6rem ;\n\n    }\n    .desktop {\n        display: block;\n    }\n    .mobile {\n        display: none;\n    }\n    li {\n        display: inline-block;\n        margin-right: 3rem;\n    }\n}\n\niframe\n{\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
