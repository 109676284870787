import React, {useEffect} from 'react';
import './Home.css';
import Projects from "../_inner/Projects";
import Menu from "../_inner/Menu";
import Elit from "../_inner/Elit";
import Pub from "../_inner/Pub";
import Tg from "../_inner/Tg";
import Dev from "../_inner/Dev";
import Cont from "../_inner/Cont";
import Social from "../_inner/social/Social"
import {useLocation} from "react-router-dom";



const Header = () => {

    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.slice(1));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location]);

    return (
        <div className={"homepage"}>
                <Menu/>
                <div className={"menu-padding"}></div>
                <div id={"proj"} className={"link-helper"}></div>
                <h1>Проекты</h1>
                <Projects/>
                <div id={"elit"} className={"link-helper"}></div>
                <h1>E-литература</h1>
                <Elit/>
                <div id={"pub"} className={"link-helper"}></div>
                <h1>Публикации</h1>
                <Pub/>
                <div id={"tgproj"} className={"link-helper"}></div>
                <h1>Телеграм: проекты</h1>
                <Tg/>
                <div id={"dev"} className={"link-helper"}></div>
                <h1>Разработка</h1>
                <Dev/>
                <div id={"about"} className={"link-helper"}></div>
                <h1>О себе</h1>
                <div className={"link-cont link-cont-1"}>Родился в Москве в 1997 году. Поэт, редактор.</div>
                <p></p>
                <div className={"link-cont link-cont-1"}> Вошел в лонг-лист премии Аркадия Драгомощенко 2020.</div>
                <p></p>
                <div className={"link-cont link-cont-1"}> С 2023 года является основателем и главным
                        редактором проекта e-литературы «web-almanac».
                </div>
                <p></p>
                <div id={"cont"} className={"link-helper"}></div>
                <h1>Контакты</h1>
                <Cont/>
                <p></p>
                <Social/>
        </div>

    );
};


export default Header;
