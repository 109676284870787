import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import React    from "react";
import Homepage from "./components/homepage/Homepage";
import Ns from "./components/ns/Ns";
import Dd from "./components/dd/Dd";
import BodyStyleManager from "./components/_inner/BodyStyleManager";
import Li from "./components/li/Li";
import Portfolio from "./components/portfolio/Portfolio";
import CDN from "./components/global_utils/CDN";
import Scratch from "./components/scratch/Scratch";
import {Versions} from "./constants/ScratchTexts";

function App() {

    return (

        <BrowserRouter>
            <BodyStyleManager />
            <Routes>
                <Route path="/" element={<Homepage/>}/>
                <Route path="/ns" element={<Ns/>}/>
                <Route path="/dd" element={<Dd/>}/>
                <Route path="/ul" element={<Li/>}/>
                <Route path="/dev/portfolio" element={<Portfolio/>}/>
                <Route path="/portfolio" element={<Portfolio/>}/>
                <Route path="/cdn" element={<CDN/>}/>
                <Route path="/scratch" element={<Scratch versions={Versions}/>}/>
            </Routes>
        </BrowserRouter>

            );

}

export default App;
