import {TgMy} from "../urls/Urls";
import {Texts} from "./Texts";

const Contacts = ({lang}) => {
    return (
        <div>
            <div className={"texts"}><h1>{Texts["contacts"][lang]}</h1></div>
            <div className={"link-cont link-cont-1"}>{Texts["contacts-1"][lang]} <a className={"link-cont"}
                                                                         href={TgMy}>@thealialiev</a></div>
            <p></p>
            <div className={"link-cont link-cont-1"}>{Texts["contacts-2"][lang]}  <a className={"link-cont"}
                                                                      href={`mailto:info@alialiev.com`}>info@alialiev.com</a>
            </div>
        </div>
    )
}

export default Contacts