import ImgDownloader from "./ImgDownloader";
import "./CDN.css";


const CDN = () => {
    return (
        <div className={"cdn"}>
        <ImgDownloader/>
        </div>
    )
}

export default CDN;
