import {Texts} from "./Texts";
import {useLocation} from "react-router-dom";

const keyHH = "about-2-hh"
const keyTchk = "about-2"
const About = ({lang}) => {
    const location = useLocation();
    let key = location.pathname === "/portfolio" ? keyHH : keyTchk
    return (
        <div className={"texts"}>
            <h1>{Texts["about"][lang]}</h1>
            <p>{Texts["about-1"][lang]}</p>
            <p>{Texts[key][lang]}</p>
            <p>{Texts["about-3"][lang]}</p>
        </div>
    )
}

export default About