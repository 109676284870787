import {Link} from "react-router-dom";
import {Pds, WebAlVol1} from "../urls/Urls";
import React from 'react';
import moon from "../static/1_moon.png";
import swrds from "../static/22_swordsman.png";
import "./Projects.css"
import TextRing from "./TextRing";
import {isMobileDeviceV2} from "../../utils/StyleUtils";
const Projects = () => {
    let isMob = isMobileDeviceV2()
    return (
        <div className={"projects"}>
            <div className={"grid-container"}>
                <div className={"grid-item"}>

                    <Link to={WebAlVol1}>
                        <TextRing side={isMob ? 1.1 : 1.2} bottom={isMob ? 1 : 0} left={isMob ? 3 : 5} turn1={0.3}
                                  turn2={0.25}>web-almanac</TextRing>
                        <img className={"link-img position-0"} src={moon} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>

                    <Link to={Pds}>
                        <TextRing side={isMob ? 1.0 : 1.0} bottom={isMob ? 5 : 5} left={isMob ? 5 : 9.5} turn1={-0.18}
                                  turn2={-0.05} revert={true}>подстрочник</TextRing>
                        <img className={"link-img position-0"} src={swrds} alt={"moon"}/>
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default Projects