import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSearchParams } from 'react-router-dom';
import {defaultOrder2, Lis, secret} from "./Texts";
import hvrus from "../static/home_version_rus.png"
import "./Li.css"
import settingsIcon from "../static/settings.svg";

const Li = () => {
    const [items, setItems] = useState(Lis);
    const [searchParams, setSearchParams] = useSearchParams();
    const [language, setLanguage] = useState(searchParams.get('lang') || "ru");
    const [textSize, setTextSize] = useState(searchParams.get('size') || "max");
    const [order, setOrder] = useState(searchParams.get('order') || defaultOrder2);
    const [settingsOpen, setSettingsOpen] = useState(false)
    const [isDownloaded, setIsDownloaded] = useState(false)
    const [isFindSecret, setIsFindSecret] = useState(false)

    useEffect(() => {
        if (language === "ru" && textSize === "mid" && order === secret){
            setIsFindSecret(true)
        }

        setSearchParams({ lang: language, size: textSize, order: order});
    }, [language, textSize, setSearchParams, order]);

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = hvrus;
        link.download = 'home_version_rus.png';
        link.click();
        setIsDownloaded(true)
    };


    useEffect(() => {
        const order = searchParams.get('order');
        if (order) {
            const orderArray = order.split(',');
            const orderedItems = orderArray.map(id => items.find(item => item.id.toString() === id)).filter(item => item);
            if (orderedItems.length === items.length) {
                setItems(orderedItems);
            }
        }
    // eslint-disable-next-line
    }, [searchParams]);

    const handleOnDragEnd = (result) => {
        if (!result.destination) return;
        const newArray = Array.from(items);
        const [reorderedItem] = newArray.splice(result.source.index, 1);
        newArray.splice(result.destination.index, 0, reorderedItem);
        setItems(newArray);
        setSearchParams({ order: newArray.map(item => item.id.toString()).join(','), lang: language, size: textSize });
        setOrder(newArray.map(item => item.id.toString()).join(','))
        console.log(newArray.map(item => item.id.toString()).join(','))
    };

    return (
        <div>
            <div className={"settings-icon-div"}><img className={"settings-icon"}
                                                      onClick={() => setSettingsOpen(!settingsOpen)} src={settingsIcon}
                                                      alt={""}/></div>
            {settingsOpen &&
                <div className={"li-buttons"}>
                    <div>
                        <button className={language === "ru" ? "active-button" : ""}
                                onClick={() => setLanguage("ru")}>RU
                        </button>
                        <button className={language === "eng" ? "active-button" : ""}
                                onClick={() => setLanguage("eng")}>ENG
                        </button>
                    </div>
                    <div>
                        <button className={textSize === "min" ? "active-button" : ""}
                                onClick={() => setTextSize("min")}>Min
                        </button>
                        <button className={textSize === "mid" ? "active-button" : ""}
                                onClick={() => setTextSize("mid")}>Mid
                        </button>
                        <button className={textSize === "max" ? "active-button" : ""}
                                onClick={() => setTextSize("max")}>Max
                        </button>
                    </div>
                    <div>
                        <button className={defaultOrder2 === order && textSize === "max" && language === "ru" ? "active-button" : ""} onClick={() => {
                            setTextSize("max")
                            setLanguage("ru")
                            setOrder(defaultOrder2)
                        }}>Default
                        </button>
                    </div>
                </div>
            }
            <div className={"helper"}></div>
            <DragDropContext onDragEnd={handleOnDragEnd}>
                <Droppable droppableId="ROOT">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={"wrapper-li"}>
                            <h3>287,401e9 poems of </h3>
                            <h1 className={"center-custom"}>&lt;/UL&gt;</h1>
                            {items.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={{
                                                ...provided.draggableProps.style,
                                                userSelect: "none",
                                                padding: 20,
                                                margin: "0 0 8px 0",
                                                backgroundColor: "#fff",
                                                color: "#333",
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                cursor: 'grab',
                                                borderRadius: "7px",
                                            }}
                                        >
                                            <div dangerouslySetInnerHTML={{__html: item[language][textSize]}}></div>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            <div className={"center-custom"} style={{paddingTop: "5rem", paddingBottom: "2rem"}}>
                                {isFindSecret &&
                                    <div style={{color: "white", display: "block"}}>Congratulations! You find secret
                                        combination! You can:
                                        <button onClick={handleDownload}>{isDownloaded ? "☑️" : "⇩"}Download Home
                                            Version
                                            (RUS)
                                        </button>
                                    </div>
                                }
                            </div>
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    );
};

export default Li;
