// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-li{
    display: block;
}

.wrapper-li{
    margin: 0 10vh 0 10vh;
    max-width: 70rem;
}

.active-button{
    background-color: aquamarine;
}

.settings-icon-div{
    position: absolute;
    right: 1rem;
    top: 1rem
}

.helper{
    height: 7rem;
}

.li-buttons{
    position: absolute;
    button{
        height: 2rem;
        width: auto;
    }
}

@media (max-width: 1400px) {
    .li-buttons{
        left: 1rem;
        top: 1rem
    }
}

@media (min-width: 1400px) {
    .li-buttons{
        right: 1rem;
        top: 10rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/li/Li.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX;AACJ;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB;QACI,YAAY;QACZ,WAAW;IACf;AACJ;;AAEA;IACI;QACI,UAAU;QACV;IACJ;AACJ;;AAEA;IACI;QACI,WAAW;QACX,UAAU;IACd;AACJ","sourcesContent":[".p-li{\n    display: block;\n}\n\n.wrapper-li{\n    margin: 0 10vh 0 10vh;\n    max-width: 70rem;\n}\n\n.active-button{\n    background-color: aquamarine;\n}\n\n.settings-icon-div{\n    position: absolute;\n    right: 1rem;\n    top: 1rem\n}\n\n.helper{\n    height: 7rem;\n}\n\n.li-buttons{\n    position: absolute;\n    button{\n        height: 2rem;\n        width: auto;\n    }\n}\n\n@media (max-width: 1400px) {\n    .li-buttons{\n        left: 1rem;\n        top: 1rem\n    }\n}\n\n@media (min-width: 1400px) {\n    .li-buttons{\n        right: 1rem;\n        top: 10rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
