import React from "react";
import "./Social.css"
import facebook from "../../static/icons8-facebook.svg"
import inst from "../../static/icons8-instagram.svg"
import tg from "../../static/icons8-telegram.svg"
import {Link} from "react-router-dom";
import {FbMy, InMy, TgMy} from "../../urls/Urls";
const Social = () => {
    return (
        <div className={"social"}>
            <h2>Социальные сети</h2>
            <div><Link to={FbMy}><img src={facebook} alt={"facebook"}/></Link></div>
            <div><Link to={InMy}><img src={inst} alt={"inst"}/></Link></div>
            <div><Link to={TgMy}><img src={tg} alt={"tg"}/></Link></div>
        </div>
    )
}

export default Social