import {Texts} from "./Texts";

export const Stack = ({lang}) => {
    return (
        <div>
            <div className={"texts"}>
                <h1>{Texts["stack-title"][lang]}</h1>
            </div>
            <div className={"texts"}>
                <h1 className={"sub-title"}>{Texts["stack-1"][lang]}</h1>
                <p className={"font-core-style"}>GoLang (core) | (TypeScript, Python, Rust, Lua)</p>
            </div>
            <div className={"texts"}>
                <h1 className={"sub-title"}>{Texts["stack-2"][lang]}</h1>
                <p className={"font-core-style"}>Sql: PostgreSQL, MySql, Sqlite && NoSql: MongoDB, Redis, Tarantool, ClickHouse</p>
                <p style={{paddingTop: "0"}} className={"font-core-style"}>NoSql: MongoDB, Redis, Tarantool, ClickHouse</p>
            </div>
            <div className={"texts"}>
                <h1 className={"sub-title"}>{Texts["stack-3"][lang]}</h1>
                <p className={"font-core-style"}>Kafka, RabbitMQ, NATS</p>
            </div>
            <div className={"texts"}>
                <h1 className={"sub-title"}>{Texts["stack-4"][lang]}</h1>
                <p className={"font-core-style"}>nginx, traefik, Docker, GitLab CI/CD, k8s</p>
            </div>
            <div className={"texts"}>
                <h1 className={"sub-title"}>{Texts["stack-5"][lang]}</h1>
                <p className={"font-core-style"}>Loki, Grafana</p>
            </div>
        </div>
    )
}