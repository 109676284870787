import React, {useState} from 'react';
import "./Portfolio.css"
import {Stack} from "./Stack";
import {Jobs} from "./Jobs";
import Contacts from "./Contacts";
import About from "./About";

const Portfolio = () => {
    const userLanguage = localStorage.getItem('language') || 'ru';
    const [lang, setLang] = useState(userLanguage)
    const handleChangeLanguage = () => {
        localStorage.setItem('language', lang === 'ru' ? 'en' : 'ru');
        setLang(lang === 'ru' ? 'en' : 'ru')
    }
    return (
        <div className={"portfolio"}>
            <div onClick={handleChangeLanguage} className={"button"}><p>{lang === 'ru' ? 'ru' : 'en'}</p></div>
            <About lang={lang}/>
            <div className={"separator"}></div>
            <Stack lang={lang}/>
            <div className={"separator"}></div>
            <Jobs lang={lang}/>
            <div className={"separator"}></div>
            <Contacts lang={lang}/>
            {/*<div className={"separator"}></div>*/}
            {/*<Details lang={lang}/>*/}
        </div>
    )
}

export default Portfolio

