import {TgMy} from "../urls/Urls";

const Cont = () => {
    return (

        <div>
            <div className={"link-cont link-cont-1"}>личный телеграм: <a className={"link-cont"}  href={TgMy}>@thealialiev</a></div>
            <p></p>
            <div className={"link-cont link-cont-1"}>личная почта: <a className={"link-cont"} href={`mailto:info@alialiev.com`}>info@alialiev.com</a></div>
            <p></p>
            <div className={"link-cont link-cont-1"}>почта wa: <a className={"link-cont"}
               href={`mailto:info@web-almanac.com?subject=${encodeURIComponent('проект web-almanac')}`}>info@web-almanac.com</a></div>
            <p></p>
        </div>

    )
}

export default Cont