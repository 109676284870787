
export const removeElementsByClass = (className) => {
    const elements = document.getElementsByClassName(className);
    while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
    }
};

export const getElBuyClassName = (className) => {
    const elements = document.getElementsByClassName(className);
    return elements[0]
};


// export const isMobileDevice = () => {
//     return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
// };

export const isMobileDeviceV2 = () => {
        return window.innerWidth < 500;
};

export const getLanguage = () => {
    const langLocal = navigator.language || navigator.userLanguage
    switch (langLocal) {
        case 'en-En':
            return 'en'
        case 'ru-Ru':
            return 'ru'
        default:
            return 'en'
    }
};