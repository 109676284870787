import {Texts} from "./Texts";
import React from "react";

export const Jobs = ({lang}) => {
    return (
        <div>
            <div className={"texts"}>
                <h1>{Texts["job"][lang]}</h1>
            </div>
            <div className={"texts"}>
                <h1 className={"sub-title"}>wildberries.ru</h1>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["wb-time"][lang]}</p>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["wb-achieve"][lang]}</p>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["wb-stack"][lang]}</p>
            </div>
            <div className={"texts"}>
                <h1 className={"sub-title"}>exnode.ru</h1>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["exnode-time"][lang]}</p>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["exnode-achieve"][lang]}</p>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["exnode-stack"][lang]}</p>
            </div>
            <div className={"texts"}>
                <h1 className={"sub-title"}>alialiev.com</h1>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["alialiev-time"][lang]}</p>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["alialiev-achieve"][lang]}</p>
                <p className={"sub-title jobs-text font-core-style"}>{Texts["alialiev-stack"][lang]}</p>
            </div>
        </div>
    )
}