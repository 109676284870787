import React, {useState, useEffect} from "react";
import "./Dd.css";
import dd1 from "./../static/dd-1.gif"
import dd2 from "./../static/dd-2.gif"
import dd3 from "./../static/dd-3.gif"
import {getLanguage} from "../ns/Ns";
import {Dd1, Dd2, Dd3} from "./Dds";
import iIcon from "./../static/i.svg"
import arrow from "./../static/arrow.gif"
import useSound from "use-sound";
import sound from "../static/dd.wav";


const Dd = () => {
    const [play] = useSound(sound, { loop: true });
    const [currentView, setCurrentView] = useState(0);
    const [loading, setLoading] = useState(true);
    const [lang, setLang] = useState(getLanguage())
    const [showI, setShowI] = useState(false)

    const handleChangeLanguage = () => {
        setLang(lang === 'ru' ? 'en' : 'ru')
    }

    const handleShowI = () => {
        setShowI(!showI)
    }

    const handleClick = (num) => {
        let newView = currentView + num
        if (newView < 0 || newView > 2){
            return
        }
        setCurrentView(newView)
    }

    useEffect(() => {
        const images = [dd1, dd2, dd3];
        const imgElements = images.map((src) => {
            const img = new Image();
            img.src = src;
            return img;
        });

        Promise.all(imgElements.map((img) => new Promise((resolve, reject) => {
            img.onload = resolve;
            img.onerror = reject;
        })))
            .then(() => setLoading(false))
            .catch((error) => console.error("Failed to load images", error));
    }, []);

    useEffect(() => {
        play();
    }, [play]);

    if (loading) {
        return (
            <div className={"dd"}>
            </div>
        )
    }
    return (
        <div className={"dd"}>
            <div className={"buttons"}>
                <div onClick={handleChangeLanguage} className={"button"}><p>{lang === 'ru' ? 'ru' : 'eng'}</p></div>
                <div className={"button center"}><img className={"i-icon"} onClick={handleShowI} src={iIcon} alt={""}/></div>
                {showI && <div className={"info button"}>ali aliev x katya mezentseva</div> }
            </div>
            <div className={"next-prev"}>
                {currentView === 0 && <div style={{height: "3.5rem", width: "3.5rem"}}></div>}
                {currentView !== 0 && <div onClick={function () {
                    handleClick(-1)
                }} className={"prev"}><img className={"arrow"} src={arrow} alt={""}/></div>}
                {currentView !== 2 && <div onClick={function () {
                    handleClick(1)
                }} className={"next"}><img className={"arrow"} src={arrow} alt={""}/></div>}
            </div>
            <div className={`view ${currentView === 0 ? 'active' : ''}`}
            >
                <div className={"card-text"}>
                    <Dd1 lg={lang}/>
                </div>
                <img className={"card"} src={dd1} alt="First"/>
            </div>
            <div className={`view ${currentView === 1 ? 'active' : ''}`}
            >
                <div className={"card-text"}>
                    <Dd2 lg={lang}/>
                </div>
                <img className={"card"} src={dd2} alt="Second"/>
            </div>
            <div className={`view ${currentView === 2 ? 'active' : ''}`}
            >
                <div className={"card-text"}>
                    <Dd3 lg={lang}/>
                </div>
                <img className={"card"} src={dd3} alt="Third"/>
            </div>
        </div>
    );
};

export default Dd;
