// BodyStyleManager.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const BodyStyleManager = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/dd') {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        // Clean up function to reset the style when the component unmounts or path changes
        return () => {
            document.body.style.overflow = '';
        };
    }, [location.pathname]);

    // This component doesn't render anything
    return null;
};

export default BodyStyleManager;
