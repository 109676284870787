
const li1 = {
    id: "li1",
    ru: {
        min: "во дворе заводится 2106",
        mid: "последний на районе 2106 заводится",
        max: "<span>последний на районе 2106 заводится пользуясь <i>той же</i> партитурой</span>"
    },
    eng: {
        min: "in the yard a chevy camaro ’69 is starting up",
        mid: "the last in the crib chevy camaro `69 is starting up",
        max: "<span>the last in the crib chevy camaro `69 is starting up, with <i>the same</i> score</span>"
    }
};

const li2 = {
    id: "li2",
    ru: {
        min: "и согревает только тепло",
        mid: " и согревает только тепло по ночам стриминговых платформ,  завернувшихся в одеяло ",
        max: " и согревает только тепло по ночам стриминговых платформ, завернувшихся в одеяло, только так что нога торчит для проветривания"
    },
    eng: {
        min: "and only heat warms",
        mid: "and only heat warms at night from streaming platforms wrapped in a blanket",
        max: "and only heat warms at night from streaming platforms wrapped in a blanket, just so a foot sticks out for ventilation"
    }
};

const li3 = {
    id: "li3",
    ru: {
        min: "хочется сказать слово как",
        mid: "хочется сказать слово как заклинание — тебе оказаться здесь",
        max: "хочется сказать слово как заклинание чтобы тебе оказаться здесь"
    },
    eng: {
        min: "I wish to say as if",
        mid: "I wish to say as if a spell — for you to turn up here",
        max: "I wish to say as if a spell you turned up here"
    }
};

const li4 = {
    id: "li4",
    ru: {
        min: "электричество лишь",
        mid: "электричество лишь для майнинговых ферм корм",
        max: "думать о таком, что электричество лишь для майнинговых ферм корм"
    },
    eng: {
        min: "electricity is",
        mid: "electricity is just food for mining farms",
        max: "to think about, electricity is just food for mining farms"
    }
};

const li5 = {
    id: "li5",
    ru: {
        min: "становились разреженными",
        mid: "становились разреженными слова",
        max: "становились разреженными при приближении к морю слова"
    },
    eng: {
        min: "became dispersed",
        mid: "Became dispersed the words",
        max: "Became dispersed the words as they approached the sea of word"
    }
};

const li6 = {
    id: "li6",
    ru: {
        min: "все еще город",
        mid: "наверное, это все еще",
        max: "наверное, это все еще город москва"
    },
    eng: {
        min: "still a city",
        mid: "probably it is still",
        max: "probably it is still NY city"
    }
};

const li7 = {
    id: "li7",
    ru: {
        min: "замкнула тебя",
        mid: "графиков, действий, кликов телеметрия, метрика",
        max: "замкнула тебя графиков, действий, кликов телеметрия, метрика"
    },
    eng: {
        min: "enclosed you",
        mid: "charts, actions, clicks telemetry, metric",
        max: "has enclosed you with charts, actions, clicks telemetry, metric"
    }
};

const li8 = {
    id: "li8",
    ru: {
        min: "обнимая себя",
        mid: "обнимая себя перестать",
        max: "перестать пытаться, обнимая себя"
    },
    eng: {
        min: "hugging oneself",
        mid: "stop hugging oneself",
        max: "stop attempting, while hugging oneself"
    }
};

const li9 = {
    id: "li9",
    ru: {
        min: "было написано",
        mid: "вещь так удивила тебя",
        max: "было написано: цвета тиффани вещь так удивила тебя"
    },
    eng: {
        min: "it was written",
        mid: "the thing surprised you so much",
        max: "it was written: Tiffany colors, the thing surprised you so much"
    }
};

const li10 = {
    id: "li10",
    ru: {
        min: "не мысль о смерти",
        mid: "не мысль о смерти, а пафос умирания",
        max: "когда тебе невозможно было выдержать " +
            "не мысль о смерти, а пафос умирания;"
    },
    eng: {
        min: "not a thought of death",
        mid: "not a thought of death, but the drama of dying",
        max: "not a thought of death, but the drama of dying when you couldnt bear it;"
    }
};

const li11 = {
    id: "li11",
    ru: {
        min: "становятся ложны",
        mid: "становятся ложны слова",
        max: "становятся ложны при приближении к морю  слова"
    },
    eng: {
        min: "become false",
        mid: "become false the words",
        max: "become false the words as a sea approaches"
    }
};

const li12 = {
    id: "li12",
    ru: {
        min: "выше неба списаний",
        mid: "заколоченный дом на горизонте выше небо списаний",
        max: "повторяется: заколоченный дом на горизонте выше небо списаний"
    },
    eng: {
        min: "above is the sky of write-offs",
        mid: "boarded-up house on the horizon above the sky of write-offs",
        max: "it repeats: boarded-up house on the horizon above the sky of write-offs",
    }
};


export const Lis = [li1,li2,li3,li4,li5,li6,li7,li8,li9,li10,li11,li12]

export const defaultOrder = "li1,li2,li3,li4,li5,li6,li7,li8,li9,li10,li11,li12"
export const defaultOrder2 = "li9,li12,li4,li6,li1,li11,li3,li10,li2,li5,li8,li7"
export const secret = "li6,li12,li9,li7,li4,li1,li11,li3,li10,li2,li5,li8"


