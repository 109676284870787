import {Link} from "react-router-dom";
import {WebAlBot, WebAlVol1} from "../urls/Urls";
import React from 'react';
import moon from "../static/1_moon.png";
import boulder from "../static/2_boulder.png";
import "./Projects.css"
import TextRing from "./TextRing";
import {isMobileDeviceV2} from "../../utils/StyleUtils";
const Projects = () => {
    let isMob = isMobileDeviceV2()
    return (
        <div className={"projects"}>
            <div className={"grid-container"}>
                <div className={"grid-item"}>

                    <Link to={WebAlVol1}>
                        <TextRing side={isMob ? 1.1 : 1.2} bottom={isMob? 1:0} left={isMob? 3 : 5} turn1={0.3} turn2={0.25}>web-almanac</TextRing>
                        <img className={"link-img position-0"} src={moon} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                <Link to={WebAlBot}>
                    <TextRing side={isMob ? 0.8 : 0.9} bottom={isMob ? 0 : 8} left={isMob ? 7 : 7.5} turn1={0.3} turn2={0.1}>web-almanac bot</TextRing>
                    <img className={"link-img position-1"} src={boulder} alt={"b"}/>
                </Link>
                </div>
            </div>
        </div>
    )
}
export default Projects