export const Texts = {
    "about": {
        "ru": "Обо мне",
        "en": "About"
    },
    "about-1": {
        "ru": "Меня зовут Али, я бэкенд разработчик, мой общий стаж более 4-х лет. У меня есть опыт как на стартапах, так и в больших компаниях с развитой кодовой базой. Люблю как начинать продукт с нуля, так и люблю заниматься рефакторингом и оптимизацией кода, развитием внутренней инфраструктуры.",
        "en": "My name is Ali, and I am a backend developer, my total experience +4 years. I have experience working with both startups and large companies with established codebases.I enjoy both starting products from scratch and refactoring and optimizing code, as well as developing internal infrastructure."
    },
    "about-2": {
        "ru": "Больше всего меня интересует фин-тех, я работал в проектах связанных с криптовалютой и Blockchain, так же я работал в финансовом отделе eCommerce. В компании для меня не менее важна идейная и эстатическая составляющая, поэтому мне бы хотелось попасть в команду разработки Точки.",
        "en": "I am particularly interested in fintech and have worked on projects related to cryptocurrency and blockchain, as well as in the financial department of eCommerce. In a company, the ideological and aesthetic components are equally important to me, which is why I would like to join the Tochka development team."
    },
    "about-2-hh": {
        "ru": "Больше всего меня интересует фин-тех, я работал в проектах связанных с криптовалютой и Blockchain, так же я работал в финансовом отделе eCommerce. Но я буду рад рассмотреть любые другие инстресные предложения.",
        "en": "I am particularly interested in fintech and have worked on projects related to cryptocurrency and blockchain, as well as in the financial department of eCommerce. However, I am open to considering any other interesting opportunities."
    },
    "about-3": {
        "ru": "Оптимистичный и неконфликтный, открытый и гибкий, быстро адаптируюсь и обучаюсь, перфекционист, объясняю простыми словами сложные вещи, могу организовать работу с технической точки зрения.",
        "en": "I am optimistic and non-confrontational, open-minded and adaptable, a quick learner, and a perfectionist. I can explain complex concepts in simple terms and organize work from a technical perspective."
    },
    "details": {
        "ru": "Детали",
        "en": "Details"
    },
    "details-1": {
        "ru": "Привет, меня зовут Али, мне 26, я бэкенд разработчик, мой общий стаж работы 4.1 года. Мой основной язык Go, я использую его для большинства своих проектов, будь то проект в инфраструктуре корпорации или мой персональный коммерческий проект. Однако, я считаю, что на некоторые задачи можно использовать более подходящие инструменты. Я также имею опыт на Rust (в рамках корпорации я писал парсер данных на нем, так же я написал  CDN на Rust, для своих персональных проектов). Так же я имею опыт на Python (он был одним из первых языков на которых я учился программированию, в работе я использовал его для написания телеграмм ботов для внутренних нужд, а персонально использовал transfollow, для написания нейросети для своего личного проекта). Так же имею опыт на TypeScript ( в рамках корпоративной работы разрабатывал бэкенд на Next.js, а в своих проектах часто использую React, чтобы написать фронтенд).",
        "en": "Hello, my name is Ali. I am 26 years old and work as a backend developer, my total experience 4.1 years. My primary programming language is Go, which I use for most of my projects, whether they are related to corporate infrastructure or my personal commercial projects. However, I believe that certain tasks can benefit from more suitable tools. I also have experience with Rust, having written a data parser and a CDN in Rust for personal projects. Additionally, I have experience with Python, which was one of the first programming languages I learned. Professionally, I have used Python to write Telegram bots for internal purposes, and personally, I used it with Transfollow to create a neural network for a personal project. I also have experience with TypeScript; within corporate work, I developed backend applications using Next.js, and in my personal projects, I often use React for frontend development."
    },
    "details-2": {
        "ru": "Своими сильными качествами в работе считаю вовлеченность в процесс, погружённость в предметную область, отзывчивость к коллегам, быстрое освоение новых инструментов и стратегий.",
        "en": "I consider my strengths to be my commitment to the process, deep engagement with the subject matter, responsiveness to colleagues, and quick adaptation to new tools and strategies."
    },
    "details-3": {
        "ru": "Своими слабыми качествами считаю невнимательность, зачастую совершаю ошибки, если мой код не покрыт на 100% тестами, поэтому стараюсь выделять как можно больше времени генерации тест-кейсов, и изучением методов тестирования.",
        "en": "My weaknesses include a tendency to make mistakes if my code is not thoroughly tested, so I strive to allocate ample time for generating test cases and studying testing methods."
    },
    "details-4": {
        "ru": "Сейчас я работаю над собой и пытаюсь освоить более детально некоторые DevOps инструменты, которые, как я считаю, я знаю не на должном уровне: k8s, traefik. Мне интерсна архитектура в широком смысле, поэтому в будущем хочу вырасти до архитектора.",
        "en": "Currently, I am focusing on improving my skills with certain DevOps tools, which I believe I do not know well enough: Kubernetes (k8s) and Traefik. I am interested in architecture in a broad sense, and I aspire to grow into an architect in the future."
    },
    "stack-title": {
        "ru": "Мой стэк",
        "en": "My stack"
    },
    "stack-1": {
        "ru": "Языки програмирования",
        "en": "Programing languages"
    },
    "stack-2": {
        "ru": "Базы данных",
        "en": "Databases"
    },
    "stack-3": {
        "ru": "Брокеры сообщений",
        "en": "Message Brokers"
    },
    "stack-4": {
        "ru": "DevOps",
        "en": "DevOps"
    },
    "stack-5": {
        "ru": "Observability",
        "en": "Observability"
    },
    "job": {
        "ru": "Опыт работы",
        "en": "Jobs"
    },
    "exnode-time": {
        "ru": "янв 2022 — авг 2023 (1.8 лет)",
        "en": "jan 2022 — aug 2023 (1.8 years)",
    },
    "exnode-achieve": {
        "ru": "Достижения: разработал SSO OAuth сервис на GO, Написал бэкенд P2P-биржи с нуля на Go, Поодежка, рефакторинг кода криптообменников на TS Next.js",
        "en": "Achieve: developed an SSO OAuth service in Go, built the backend for a P2P exchange from scratch using Go, and supported and refactored the code for cryptocurrency exchanges using TypeScript and Next.js.",
    },
    "exnode-stack": {
        "ru": "Stack: Golang, Fiber, Redis, RabbitMQ, Kafka, TS, Next.Js, PostgresSQl, Sqlite, Python, MongoDB, Nginx",
        "en": "Stack: Golang, Fiber, Redis, RabbitMQ, Kafka, TS, Next.Js, PostgresSQl, Sqlite, Python, MongoDB, Nginx",
    },
    "wb-time": {
        "ru": "авг 2023 — по настоящее время (0.9 лет)",
        "en": "aug 2023 — now (0.9 years)",
    },
    "wb-achieve": {
        "ru": "Достижения: разработал ядро валидации для команды построенное на reflection, разработал ядро авторизации для команды, написал множество микросервисов для финаснсового контура",
        "en": "Achievements: developed a validation framework for the team using reflection, created an authorization core for the team, and wrote numerous microservices for the financial sector.",
    },
    "wb-stack": {
        "ru": "Stack: Golang, Gin, NATS, Tarantool, Kafka, Clickhouse, PostgresSQl, Rust, Docker, Traefik",
        "en": "Stack: Golang, Gin, NATS, Tarantool, Kafka, Clickhouse, PostgresSQl, Rust, Docker, Traefik",
    },
    "alialiev-time": {
        "ru": "май 2020 — янв 2022 (1.9 лет)",
        "en": "may 2020 — jan 2022 (1.9 years)",
    },
    "alialiev-achieve": {
        "ru": "Достижения: создание сайтов-журналов, сайтов-визиток, написал свое ядро для быстрого создание бэка веб приложения на MongoDB + Go",
        "en": "Achievements: created magazine websites, business card websites, and developed my own framework for rapid backend development of web applications using MongoDB and Go.",
    },
    "alialiev-stack": {
        "ru": "Stack: Golang, Python, React | PostgresSQl, MongoDB, MySql | Nginx, Traefik",
        "en": "Stack: Golang, Python, React | PostgresSQl, MongoDB, MySql | Nginx, Traefik",
    },
    "contacts": {
        "ru": "Контакты",
        "en": "Contacts"
    },
    "contacts-1": {
        "ru": "телеграм:",
        "en": "telegram:"
    },
    "contacts-2": {
        "ru": "почта:",
        "en": "mail:"
    },
}