import {Link} from "react-router-dom";
import {TgPod, TgUl, TgWebAl} from "../urls/Urls";
import TextRing from "./TextRing";
import mushroom from "../static/18_mushroom.png";
import walnut from "../static/6_walnut.png";
import dog from "../static/12_dog in boots.png";
import {isMobileDeviceV2} from "../../utils/StyleUtils";

const Tg = () => {
    const isMob = isMobileDeviceV2()
    return (
        <div className={"projects"}>
            <div className={"grid-container"}>
                <div className={"grid-item"}>
                    <Link to={TgWebAl}>
                        <TextRing side={isMob ? 1.00 : 1.0} left={isMob ? 4 : 3} bottom={isMob ? 1 : 0}>tg web-almanac</TextRing>
                        <img className={"link-img"} src={mushroom} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={TgUl}>
                        <TextRing side={isMob ? 0.50 : 0.8} left={isMob ? 6 : 6} turn1={0.3} turn2={0.05} bottom={isMob ? 1 : 12}>ул' новое неопубликованное</TextRing>
                        <img className={"link-img position-1"} src={walnut} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={TgPod}>
                        <TextRing side={isMob ? 1.0 : 1.0} turn1={isMob ? -0.3 : -0.3} turn2={isMob ? -0.25 : -0.25} left={isMob ? 1 : 11} bottom={isMob ? 7 : 7} revert={true}>подстрочник</TextRing>
                        <img className={"link-img position-3"} src={dog} alt={"moon"}/>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Tg