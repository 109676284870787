// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cdn{
    background: white;
    font-family: "ISOCPEUR", serif;
}`, "",{"version":3,"sources":["webpack://./src/components/global_utils/CDN.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,8BAA8B;AAClC","sourcesContent":[".cdn{\n    background: white;\n    font-family: \"ISOCPEUR\", serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
