import {isMobileDeviceV2} from "../../utils/StyleUtils";

const TextRing = ({children, side, bottom, left, turn1, turn2, revert}) => {
    const CHARS = children.split('')
    const INNER_ANGLE = 100 / CHARS.length
    let l = left ? left : 0
    let t1 = turn1 ? turn1 : 0.2
    let t2 = turn2 ? turn2 : 0.25
    let ch = revert ? 1: -1
    if (isMobileDeviceV2()){
        return (
            <span
                className="text-ring"
                style={{
                    '--total': CHARS.length,
                    '--radius': side / Math.sin(INNER_ANGLE / (250 / Math.PI)),
                    '--turn1': `${t1}turn`,
                    '--turn2': `${t2}turn`,
                    '--revert': `${ch}ch`,
                    '--bottom': `${bottom}`,
                    '--left': l,
                }}
            >
      {CHARS.map((char, index) => (
          <span id={index} style={{'--index': index }}>
          {char}
        </span>
      ))}
    </span>
        )
    }
    return (
        <span
            className="text-ring"
            style={{
                '--total': CHARS.length,
                '--radius': side / Math.sin(INNER_ANGLE / (250 / Math.PI)),
                '--bottom': `${bottom}`,
                '--left': l,
                '--turn1': `${t1}turn`,
                '--turn2': `${t2}turn`,
                '--revert': `${ch}ch`,
            }}
        >
      {CHARS.map((char, index) => (
          <span id={index} style={{'--index': index }}>
          {char}
        </span>
      ))}
    </span>
    )
}
export default TextRing