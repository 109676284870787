import {Link} from "react-router-dom";
import {AR, KM} from "../urls/Urls";
import TextRing from "./TextRing";
import oyster from "../static/5_oyster.png";
import monstera from "../static/17_monstera.png";
import {isMobileDeviceV2} from "../../utils/StyleUtils";

const Dev = () => {
    const isMob = isMobileDeviceV2()
    return (
        <div className={"projects"}>
            <div className={"grid-container"}>
                <div className={"grid-item"}>
                    <Link to={KM}>
                        <TextRing side={isMob ? 0.8 : 1} bottom={isMob ? 4 : 4} left={isMob ? 4 : 10} turn1={0.26}
                                  turn2={-0.005}>katyamezentseva.com</TextRing>
                        <img className={"link-img position-0"} src={oyster} alt={"moon"}/>
                    </Link>
                </div>
                <div className={"grid-item"}>
                    <Link to={AR}>
                        <TextRing side={isMob ? 0.8 : 1} bottom={isMob ? 0 : -1} left={isMob ? 8 : 14} turn1={0.26}
                                  turn2={-0.005}>areas.work</TextRing>
                        <img className={"link-img position-0"} src={monstera} alt={"moon"}/>
                    </Link>
                </div>
            </div>
        </div>
        )
}

export default Dev