import React, { useState, useEffect } from "react";
import { diffChars } from "diff"; // or another diff library
import "./Scratch.css"
import {Dock} from "../../constants/ScratchTexts";

const Scratch = ({ versions }) => {
    const [currentVersion, setCurrentVersion] = useState(versions[versions.length - 1]);
    const [previousVersion, setPreviousVersion] = useState(null);
    const [showDiff, setShowDiff] = useState(false);
    const [showDock, setShowDock] = useState(false);

    useEffect(() => {
        const currentIndex = versions.findIndex(v => v.version === currentVersion.version);
        setPreviousVersion(currentIndex > 0 ? versions[currentIndex - 1] : null);
    }, [currentVersion, versions]);

    const highlightDifferences = (oldText, newText) => {
        const diff = diffChars(oldText, newText);
        return diff.map((part, index) => (
            <span
                key={index}
                style={{
                    backgroundColor: part.added ? "lightgreen" : part.removed ? "lightcoral" : "none"
                }}
            >
        {part.value}
      </span>
        ));
    };

    const renderText = (text) => {
        if (showDiff) {
            return text; // Show with diff classes
        } else {
            // Remove `text-added` and `text-removed` classes when `showDiff` is false
            return text.replace(/text-added|text-removed/g, "");
        }
    };

    const onClose = () => {
        setShowDock(false)
    }


    return (
        <div className={"scratch"}>
            {/* Version Selector */}
            {/*{showDock && <div className={"modal"} dangerouslySetInnerHTML={{__html: Dock}}></div>}*/}
            {showDock && <Modal onClose={onClose} textHtml={Dock}/>}
            <select className={"version"}
                    value={currentVersion.version}
                    onChange={(e) =>
                        setCurrentVersion(versions.find(v => v.version === e.target.value))
                    }
            >
                {versions.map((v) => (
                    <option key={v.version} value={v.version}>
                        {v.version}
                    </option>
                ))}
            </select>
            <div className={"text-container"}>
                {/*<h2>scratch {currentVersion}</h2>*/}
                <h1 style={{textAlign: "center"}}>scratch {currentVersion.version}</h1>
                <div className={"text"} dangerouslySetInnerHTML={{__html: renderText(currentVersion.text)}}></div>
            </div>

            {/* "See New" Button */}
            {/*{previousVersion && (*/}
            <button className={"diff"} onClick={() => setShowDiff(!showDiff)}>
                {showDiff ? "off" : "on"}
            </button>
            <button className={"diff dock"} onClick={() => setShowDock(!showDock)}>
                dock!
            </button>

            {/*)}*/}
        </div>
    );
};

const Modal = ({textHtml, onClose}) => {
    return (
        <div className={"modal"}>
            <div className="modal-overlay">
                <div className="modal-container">
                    <div className="modal-body" dangerouslySetInnerHTML={{__html: textHtml}}>
                    </div>
                    <div className="modal-footer">
                        <button className="close-button" onClick={onClose}>
                            закрыть
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Scratch;
