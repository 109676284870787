export const Dd1 = ({lg}) => {
    switch (lg) {
        case "ru":
            return (
                <>
                    <p>буферные таблицы сосков,</p>
                    <p>брокеры сообщений частотой такта, не</p>
                    <p>процессора,</p>
                    <p>но человеческого сердца,</p>
                    <p>иногда если это не кино я не могу дышать</p>
                </>
            )
        case "en":
            return (
                <>
                    <p>buffer table of nipples,</p>
                    <p>message brokers by frequency measure, not</p>
                    <p>CPU, </p>
                    <p>but a human’s heart,</p>
                    <p>sometimes if it’s not cinema I can’t breathe</p>
                </>
            )
        default:
            return (
                <>
                    <p>buffer table of nipples,</p>
                    <p>message brokers by frequency measure, not</p>
                    <p>CPU, </p>
                    <p>but a human’s heart,</p>
                    <p>sometimes if it’s not cinema I can’t breathe</p>
                </>
            )
    }
}
export const Dd2 = ({lg}) => {
    switch (lg) {
        case "ru":
            return (
                <>
                    <p>кол забивает смерть</p>
                    <p>случай забирает жизнь</p>
                    <p>воздух <i>накаляется</i></p>
                    <br/>
                    <p>как будто не на моем языке:</p>
                    <p>сладкая-сладкая цифровая смерть,</p>
                    <p>веером калейдоскопом прихода на горизонте</p>
                </>
            )
        case "en":
            return (
                <>
                    <p>wooden stake kills the death</p>
                    <p>an accident takes a life</p>
                    <p>the air is <i>heating up</i></p>
                    <br/>
                    <p>as if not in my language:</p>
                    <p>sweet-sweet digital death, by hand </p>
                    <p>fan kaleidoscope of a drug hit on the horizon</p>
                </>
            )
        default:
            return (
                <>
                    <p>wooden stake kills the death</p>
                    <p>an accident takes a life</p>
                    <p>the air is <i>heating up</i></p>
                    <br/>
                    <p>as if not in my language:</p>
                    <p>sweet-sweet digital death, by hand </p>
                    <p>fan kaleidoscope of a drug hit on the horizon</p>
                </>
            )
    }
}

export const Dd3 = ({lg}) => {
    switch (lg) {
        case "ru":
            return (
                <>
                    <p>иногда если это не кино я не могу дышать</p>
                    <p>воздух трясется в глазах</p>
                    <p>вдоль&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;тоски</p>
                    <p>я хочу <i>забирать</i> её с собой</p>
                </>
            )
        case "en":
            return (
                <>
                    <p>sometimes if it’s not cinema I can’t breathe</p>
                    <p>air is shaking in my eyes</p>
                    <p>along&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;melancholy</p>
                    <p>I want <i>to take</i> her with me</p>
                </>
            )
        default:
            return (
                <>

                    <p>sometimes if it’s not cinema I can’t breathe</p>
                    <p>air is shaking in my eyes</p>
                    <p>along&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;melancholy</p>
                    <p>I want <i>to take</i> her with me</p>
                </>
            )
    }
}
